<template>
  <div>
    <PageHeader>
      <template slot="right">
        <form class="form-inline">
          <div class="form-group mb-sm-0 mr-2"> </div>
        </form>
      </template>
    </PageHeader>
    <b-row v-if="isMaster || isOwner" class="mb-3">
      <b-col v-if="isOwner" cols="12" md="4">
        <MasterSelectInput 
          :value="masterId"
          hide-label
          @update="onMasterIdChange"
        />
      </b-col>
      <b-col v-if="isMaster || isOwner" cols="12" md="4">
        <AgentsSelectInput
          :value="agentId"
          :master-id="masterId"
          hide-label
          @update="onAgentIdChange"
        />
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" sm="8" md="8" lg="4">
        <b-form-group
          label-cols-sm="2"
          label-cols-md="2"
          label-cols-lg="2"
          :label="$t('fields.date')"
        >
          <day-picker
            :initial-value="selectedDate"
            @update="(date) => selectedDate = date"
          />
        </b-form-group>
      </b-col>
      <b-col class="text-right">
        <b-button @click="fetchData">
          ดึงผลสรุปยอด
        </b-button>
      </b-col>
    </b-row>

    <h3>
      สรุปยอดเดือนนี้
      <small class="text-muted">
        ({{ startOfMonthString }} - {{ searchingDateString }})
      </small>
    </h3>
    <hr/>
    <b-row>
      <b-col v-for="stat of mainStats" :key="stat.id" cols="12" sm="6" lg="4">
        <WidgetSingleStatIcon
          :title="stat.title"
          :unit="stat.unit"
          :value="stat.value | money"
          :color="stat.color"
          :icon="stat.icon"
          :subs="stat.subs"
          :is-loading="isFetchingDashboard"
        />
      </b-col>
    </b-row>
    <h3>
      สรุปยอดวันนี้
      <small class="text-muted">
        ({{ searchingDateString }})
      </small>
    </h3>
    <hr/>
    <b-row>
      <b-col v-for="stat of todayMainStats" :key="stat.id" cols="12" sm="6" lg="4">
        <WidgetSingleStatIcon
          class="bg-light"
          :title="stat.title"
          :unit="stat.unit"
          :value="stat.value | money"
          :color="stat.color"
          :icon="stat.icon"
          :subs="stat.subs"
          :is-loading="isFetchingDashboard"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <WidgetStatsIcon
          title="สรุปโปรโมชั่นเดือนนี้"
          :items="totalOverviews"
          :is-loading="isFetchingDashboard"
        />
      </b-col>
      <b-col>
        <WidgetStatsIcon
          title="สรุปโปรโมชั่นวันนี้"
          :items="todayOverviews"
          :is-loading="isFetchingDashboard"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  page: {
    title: 'Dashboard',
  },
  components: {
    WidgetSingleStatIcon: () =>
      import('@components/widgets/single-stat-icon'),
    WidgetStatsIcon: () => import('@components/widgets/stats-icon'),
    AgentsSelectInput: () =>
      import('@components/agents/agents-select-input'),
    MasterSelectInput: () =>
      import('@components/master/master-select-input'),
  },
  data() {
    return {
      agentId: '',
      masterId: '',
      selectedDate: '',
      searchingDate: '',
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dashboard', 'isOwner', 'isMaster']),
    ...mapState({
      isFetchingDashboard: (state) => state.user.isFetchingDashboard,
      DepositStatusEnum: (state) => state.user.DepositStatusEnum,
      WithdrawStatusEnum: (state) => state.user.WithdrawStatusEnum,
      PromotionTypeEnum: (state) => state.user.PromotionTypeEnum,
      RegisterFromEnum: (state) => state.user.RegisterFromEnum,
    }),
    startOfMonthString() {
      return dayjs().startOf('month').format('DD/MM/YYYY')
    },
    searchingDateString() {
      return dayjs(this.searchingDate || new Date()).format('DD/MM/YYYY')
    },
    totalDeposit() {
      const { total } = this.dashboard;
      return (+total.deposit[this.DepositStatusEnum.SUCCESS] + +total.deposit[this.DepositStatusEnum.MANUAL])
    },
    todayDeposit() {
      const { today } = this.dashboard;
      return (+today.deposit[this.DepositStatusEnum.SUCCESS] + +today.deposit[this.DepositStatusEnum.MANUAL])
    },
    totalWithdraw() {
      const { total } = this.dashboard;
      return (+total.withdraw[this.WithdrawStatusEnum.SUCCESS] + +total.withdraw[this.WithdrawStatusEnum.MANUAL])
    },
    todayWithdraw() {
      const { today } = this.dashboard;
      return (+today.withdraw[this.WithdrawStatusEnum.SUCCESS] + +today.withdraw[this.WithdrawStatusEnum.MANUAL])
    },
    mainStats() {
      const { total } = this.dashboard;
      return [
        {
          title: 'สมาชิกเดือนนี้',
          value: total.register_count,
          color: 'primary',
          icon: 'users',
          unit: `/ ${this.$t('fields.person')}`,
          subs: [
            `ไลน์ ${this.$options.filters.money(total.register_from[this.RegisterFromEnum.LINE])}`,
            `กูเกิ้ล ${this.$options.filters.money(total.register_from[this.RegisterFromEnum.GOOGLE])}`,
            `เฟสบุ๊ค ${this.$options.filters.money(total.register_from[this.RegisterFromEnum.FACEBOOK])}`,
            `เพื่อนแนะนำ ${this.$options.filters.money(total.register_from[this.RegisterFromEnum.FRIEND])}`,
            `ป้ายโฆษณา ${this.$options.filters.money(total.register_from[this.RegisterFromEnum.ADS_BANNER])}`,
            `อื่นๆ ${this.$options.filters.money(total.register_from[this.RegisterFromEnum.OTHER])}`,
            `เติมเงิน ${this.$options.filters.money(total.register_and_deposited_user_count)} คน / ${this.$options.filters.money(total.register_and_deposited_amount)} บาท`,
          ]
        },
        {
          title: 'รับเครดิตฟรีเดือนนี้',
          value: total.promotion[this.PromotionTypeEnum.FREE_CREDIT]?.bonus_amount || 0,
          color: 'success',
          icon: 'dollar-sign',
          unit: `/ ${this.$t('fields.credit')}`,
        },
        {
          title: 'จำนวนเครดิตฟรีที่เสียเดือนนี้',
          value: total.promotion[this.PromotionTypeEnum.FREE_CREDIT]?.withdraw_amount || 0,
          color: 'success',
          icon: 'dollar-sign',
          unit: `/ ${this.$t('fields.credit')}`,
        },
        {
          title: 'ยอดฝากรวมเดือนนี้',
          value: this.totalDeposit,
          color: 'info',
          icon: 'plus-circle',
          unit: `/ ${this.$t('fields.credit')}`,
          subs: [
            `ฝากออโต้ ${this.$options.filters.money(total.deposit[this.DepositStatusEnum.SUCCESS])}`,
            `ฝากครั้งแรก ${this.$options.filters.money(total.deposit[this.DepositStatusEnum.FIRST_DEPOSIT].user_count)} คน / ${this.$options.filters.money(total.deposit[this.DepositStatusEnum.FIRST_DEPOSIT].amount)} บาท`,
            `ฝากมือ ${this.$options.filters.money(total.deposit[this.DepositStatusEnum.MANUAL])}`,
            `รอดำเนินการ ${this.$options.filters.money(total.deposit[this.DepositStatusEnum.UN_MATCH])}`,
            '<hr>',
            ...(Object.keys(total.deposit_by_bank_account || {}).map((agentBankId) => {
              const agentBankSummary = total.deposit_by_bank_account[agentBankId]
              return `${agentBankSummary.bank_account_number} (${this.$options.filters.money(agentBankSummary.amount)})`
            }))
          ],
        },
        {
          title: 'ยอดถอนรวมเดือนนี้',
          value: this.totalWithdraw,
          color: 'warning',
          icon: 'minus-circle',
          unit: `/ ${this.$t('fields.credit')}`,
          subs: [
            `ถอนออโต้ ${this.$options.filters.money(total.withdraw[this.WithdrawStatusEnum.SUCCESS])}`,
            `ถอนมือ ${this.$options.filters.money(total.withdraw[this.WithdrawStatusEnum.MANUAL])}`,
            '<hr>',
            ...(Object.keys(total.withdraw_by_bank_account || {}).map((agentBankId) => {
              const agentBankSummary = total.withdraw_by_bank_account[agentBankId]
              return `${agentBankSummary.bank_account_number} (${this.$options.filters.money(agentBankSummary.amount)})`
            }))
          ]
        },
        {
          title: 'สรุปรายได้เดือนนี้',
          value: (this.totalDeposit - this.totalWithdraw),
          color: 'info',
          icon: 'activity',
          unit: `/ ${this.$t('fields.credit')}`,
        },
      ]
    },
    todayMainStats() {
      const { today } = this.dashboard;
      return [
        {
          title: 'สมาชิกวันนี้',
          value: today.register_count,
          color: 'primary',
          icon: 'users',
          unit: `/ ${this.$t('fields.person')}`,
          subs: [
            `ไลน์ ${this.$options.filters.money(today.register_from[this.RegisterFromEnum.LINE])}`,
            `กูเกิ้ล ${this.$options.filters.money(today.register_from[this.RegisterFromEnum.GOOGLE])}`,
            `เฟสบุ๊ค ${this.$options.filters.money(today.register_from[this.RegisterFromEnum.FACEBOOK])}`,
            `เพื่อนแนะนำ ${this.$options.filters.money(today.register_from[this.RegisterFromEnum.FRIEND])}`,
            `ป้ายโฆษณา ${this.$options.filters.money(today.register_from[this.RegisterFromEnum.ADS_BANNER])}`,
            `อื่นๆ ${this.$options.filters.money(today.register_from[this.RegisterFromEnum.OTHER])}`,
            `เติมเงิน ${this.$options.filters.money(today.register_and_deposited_user_count)} คน / ${this.$options.filters.money(today.register_and_deposited_amount)} บาท`,
          ]
        },
        {
          title: 'รับเครดิตฟรีวันนี้',
          value: today.promotion[this.PromotionTypeEnum.FREE_CREDIT]?.bonus_amount || 0,
          color: 'success',
          icon: 'dollar-sign',
          unit: `/ ${this.$t('fields.credit')}`,
        },
        {
          title: 'จำนวนเครดิตฟรีที่เสียวันนี้',
          value: today.promotion[this.PromotionTypeEnum.FREE_CREDIT]?.withdraw_amount || 0,
          color: 'success',
          icon: 'dollar-sign',
          unit: `/ ${this.$t('fields.credit')}`,
        },
        {
          title: 'ยอดฝากรวมวันนี้',
          value: this.todayDeposit,
          color: 'info',
          icon: 'plus-circle',
          unit: `/ ${this.$t('fields.credit')}`,
          subs: [
            `ฝากออโต้ ${this.$options.filters.money(today.deposit[this.DepositStatusEnum.SUCCESS])}`,
            `ฝากครั้งแรก ${this.$options.filters.money(today.deposit[this.DepositStatusEnum.FIRST_DEPOSIT].user_count)} คน / ${this.$options.filters.money(today.deposit[this.DepositStatusEnum.FIRST_DEPOSIT].amount)} บาท`,
            `ฝากมือ ${this.$options.filters.money(today.deposit[this.DepositStatusEnum.MANUAL])}`,
            `รอดำเนินการ ${this.$options.filters.money(today.deposit[this.DepositStatusEnum.UN_MATCH])}`,
            '<hr>',
            ...(Object.keys(today.deposit_by_bank_account || {}).map((agentBankId) => {
              const agentBankSummary = today.deposit_by_bank_account[agentBankId]
              return `${agentBankSummary.bank_account_number} (${agentBankSummary.amount})`
            }))
          ],
        },
        {
          title: 'ยอดถอนรวมวันนี้',
          value: this.todayWithdraw,
          color: 'warning',
          icon: 'minus-circle',
          unit: `/ ${this.$t('fields.credit')}`,
          subs: [
            `ถอนออโต้ ${this.$options.filters.money(today.withdraw[this.WithdrawStatusEnum.SUCCESS])}`,
            `ถอนมือ ${this.$options.filters.money(today.withdraw[this.WithdrawStatusEnum.MANUAL])}`,
            '<hr>',
            ...(Object.keys(today.withdraw_by_bank_account || {}).map((agentBankId) => {
              const agentBankSummary = today.withdraw_by_bank_account[agentBankId]
              return `${agentBankSummary.bank_account_number} (${this.$options.filters.money(agentBankSummary.amount)})`
            }))
          ],
        },
        {
          title: 'สรุปรายได้วันนี้',
          value: (this.todayDeposit - this.todayWithdraw),
          color: 'info',
          icon: 'activity',
          unit: `/ ${this.$t('fields.credit')}`,
        },
      ]
    },
    totalOverviews() {
      const { total } = this.dashboard;
      return [
        {
          icon: 'pocket',
          value: `${this.$options.filters.money(total.promotion[this.PromotionTypeEnum.FIRST_DEPOSIT]?.user_count || 0)}
          /${this.$options.filters.money(total.promotion[this.PromotionTypeEnum.FIRST_DEPOSIT]?.deposit_amount || 0)}`,
          title: 'เติมเงินครั้งแรก',
          unit: '/คน/เครดิต',
        },
        {
          icon: 'calendar',
          value: `${this.$options.filters.money(total.promotion[this.PromotionTypeEnum.DAILY_DEPOSIT]?.user_count || 0)}
          /${this.$options.filters.money(total.promotion[this.PromotionTypeEnum.DAILY_DEPOSIT]?.deposit_amount || 0)}`,
          title: 'ฝากเงินครั้งแรกของวัน',
          unit: '/คน/เครดิต',
        },
        {
          icon: 'gift',
          value: `${this.$options.filters.money(total.promotion[this.PromotionTypeEnum.ALL_TIME]?.user_count || 0)}
          /${this.$options.filters.money(total.promotion[this.PromotionTypeEnum.ALL_TIME]?.deposit_amount || 0)}`,
          title: 'รับโบนัสทุกยอดฝาก',
          unit: '/คน/เครดิต',
        },
        {
          icon: 'clock',
          value: `${this.$options.filters.money(total.promotion[this.PromotionTypeEnum.SPECIAL_TIME]?.user_count || 0)}
          /${this.$options.filters.money(total.promotion[this.PromotionTypeEnum.SPECIAL_TIME]?.deposit_amount || 0)}`,
          title: 'ฝากเป็นเวลา',
          unit: '/คน/เครดิต',
        },
        {
          icon: 'compass',
          value: `${this.$options.filters.money(total.promotion[this.PromotionTypeEnum.RANDOM_WHEEL]?.user_count || 0)}
          /${this.$options.filters.money(total.promotion[this.PromotionTypeEnum.RANDOM_WHEEL]?.deposit_amount || 0)}`,
          title: 'หมุนวงล้อ',
          unit: '/คน/เครดิต',
        },
        {
          icon: 'square',
          value: `${this.$options.filters.money(total.promotion[this.PromotionTypeEnum.RANDOM_CARD]?.user_count || 0)}
          /${this.$options.filters.money(total.promotion[this.PromotionTypeEnum.RANDOM_CARD]?.deposit_amount || 0)}`,
          title: 'เปิดไพ่',
          unit: '/คน/เครดิต',
        },
      ]
    },
    todayOverviews() {
      const { today } = this.dashboard;
      return [
        {
          icon: 'pocket',
          value: `${this.$options.filters.money(today.promotion[this.PromotionTypeEnum.FIRST_DEPOSIT]?.user_count || 0)}
          /${this.$options.filters.money(today.promotion[this.PromotionTypeEnum.FIRST_DEPOSIT]?.deposit_amount || 0)}`,
          title: 'เติมเงินครั้งแรก',
          unit: '/คน/เครดิต',
        },
        {
          icon: 'calendar',
          value: `${this.$options.filters.money(today.promotion[this.PromotionTypeEnum.DAILY_DEPOSIT]?.user_count || 0)}
          /${this.$options.filters.money(today.promotion[this.PromotionTypeEnum.DAILY_DEPOSIT]?.deposit_amount || 0)}`,
          title: 'ฝากเงินครั้งแรกของวัน',
          unit: '/คน/เครดิต',
        },
        {
          icon: 'gift',
          value: `${this.$options.filters.money(today.promotion[this.PromotionTypeEnum.ALL_TIME]?.user_count || 0)}
          /${this.$options.filters.money(today.promotion[this.PromotionTypeEnum.ALL_TIME]?.deposit_amount || 0)}`,
          title: 'รับโบนัสทุกยอดฝาก',
          unit: '/คน/เครดิต',
        },
        {
          icon: 'clock',
          value: `${this.$options.filters.money(today.promotion[this.PromotionTypeEnum.SPECIAL_TIME]?.user_count || 0)}
          /${this.$options.filters.money(today.promotion[this.PromotionTypeEnum.SPECIAL_TIME]?.deposit_amount || 0)}`,
          title: 'ฝากเป็นเวลา',
          unit: '/คน/เครดิต',
        },
        {
          icon: 'compass',
          value: `${this.$options.filters.money(today.promotion[this.PromotionTypeEnum.RANDOM_WHEEL]?.user_count || 0)}
          /${this.$options.filters.money(today.promotion[this.PromotionTypeEnum.RANDOM_WHEEL]?.deposit_amount || 0)}`,
          title: 'หมุนวงล้อ',
          unit: '/คน/เครดิต',
        },
        {
          icon: 'square',
          value: `${this.$options.filters.money(today.promotion[this.PromotionTypeEnum.RANDOM_CARD]?.user_count || 0)}
          /${this.$options.filters.money(today.promotion[this.PromotionTypeEnum.RANDOM_CARD]?.deposit_amount || 0)}`,
          title: 'เปิดไพ่',
          unit: '/คน/เครดิต',
        },
      ]
    },
  },
  methods: {
    ...mapActions(['fetchDashboard']),
    fetchData() {
      this.fetchDashboard({
        agentId: this.agentId, 
        masterId: this.masterId, 
        date: this.selectedDate? dayjs(this.selectedDate).format('YYYY-MM-DD'): '',
      })
      this.searchingDate = this.selectedDate
    },
    onAgentIdChange(agentId) {
      this.agentId = agentId
    },
    onMasterIdChange(masterId) {
      this.masterId = masterId
    },
  },
}
</script>